<template>
  <div class="main-content">
    <div class="main-menu">
        <ul class="menu-list">
          <li class="menu-li" v-for="(item, index) in menuList" :key="index"
              :class="{active: currentRoute(item)}" >
            <div class="menu-item">
              <router-link :to="item.path">{{item.meta.title}}</router-link>
            </div>
          </li>
        </ul>
    </div>
    <div class="main-body">
      <div class="body-content">
        <div class="el-menu-com">
          <el-menu
              :default-active="defaultIndex"
              class="el-menu-demo"
              mode="horizontal"
              :ellipsis="false"
              @select="handleSelect"
          >
            <el-menu-item :index="item.name" v-for="(item, index) in subMenuList" :key="index" v-show="tapdShow(item)">
              <router-link style="text-decoration: none;" :to="item.path">{{item.meta.title}}</router-link>
            </el-menu-item>
          </el-menu>
        </div>
        <router-view></router-view>
      </div>

    </div>
  </div>

</template>

<script setup>
import {useStore} from "vuex"
import {computed, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router"
const store = useStore()
const route = useRoute()
const tapdShow = (item) => {
   if (item.meta.hide) {
     return item.meta.hide && item.name === route.name
   }
   return true
}
const menuList = computed(() => {
  return store.state.menu[0].children
})
const subMenuList = computed(() => {
  return store.state.subMenu
})
const defaultIndex = ref('');
defaultIndex.value = route.name;
const currentRoute = (item) => {
  return item.name === route.meta.parentName;
}
watch(route, (to, from) => {
    tapdChange(to.meta.parentName)
    defaultIndex.value = to.name
})

const tapdChange = (parentName) => {
  menuList.value.map((item) => {
    if (item.name === parentName) {
      store.dispatch('asyncSetSubmenu', item.children)
    }
  })
}
tapdChange(route.meta.parentName)

const handleSelect = (val) => {
}

</script>

<style scoped lang="scss">
.main-content {
    &, & * {
      box-sizing: border-box;
    }
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .main-menu {
        padding-top: 20px;
        width: 210px;
        height: 100%;
        ul,li {
          padding: 0;
          margin: 0;
          list-style: none;
        }

        ul.menu-list {
            .menu-item {
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                a {
                  text-decoration: none;
                  color: #666
                }

            }
        }
        li.active {
          background-color: #d9ecff;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 100%;
            top: 0;
            left: 0px;
            background-color:  #337ecc;
          }
          .menu-item a {
            color: #337ecc;
          }
        }
    }
    .main-body {
        flex: 1;
        height: 100%;
        background-color: #F6F8F9;
        .body-content {
            margin: 20px;
            background-color: #FFFFFF;
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            .el-menu-com {
                padding: 10px 10px 0 10px;
            }
        }
    }
;
}


</style>