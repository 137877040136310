import { createStore } from 'vuex'

export default createStore({
  state: {
    menu: [],
    subMenu: [],
  },
  mutations: {
    setMenu(state, payload) {
        state.menu = payload
    },
    setSubmenus(state, payload) {
        state.subMenu = payload
    }
  },
  actions: {
    asyncSetMenu({commit}, payload) {
        commit('setMenu', payload)
    },
    asyncSetSubmenu({commit}, payload) {
        commit('setSubmenus', payload)
    }
  },
  modules: {
  }
})
