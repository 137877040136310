import { createRouter, createWebHashHistory } from 'vue-router'
import AdminLayout from "@/components/layout/adminLayout"
import HeaderLayout from "@/components/layout/headerLayout"

export const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import("@/views/Index"),
    meta: {
        title: "年度树向生长",
    }
  },
  {
    path: '/login',
    name: 'AdminLogin',
    component: () => import("../views/Login"),
    meta : {
        title: '登录'
    }
  }
]
export const adminRoute = [
  {
    path: '/admin',
    name: 'Admin',
    component: HeaderLayout,
    redirect: '/admin/activity/index',
    children: [
      {
        path: '/admin/activity',
        redirect: '/admin/activity/index',
        name: 'Activity',
        component: AdminLayout,
        meta: {
          title: '活动管理'
        },
        children: [
          {
            path: 'index',
            name: 'ActivityIndex',
            component: () => import("../views/admin/activity/Index"),
            meta: {
              title: '活动设置',
              role: 1,
              parentName: "Activity"
            }
          }
        ]
      },
      {
        path: '/admin/bgm',
        name: 'BGM',
        redirect: '/admin/bgm/index',
        component: AdminLayout,
        meta: {
          title: '背景图管理'
        },
        children: [
          {
            path: 'index',
            name: 'BGMIndex',
            component: () => import("../views/admin/backgroundImage/Index"),
            meta: {
              title: '背景图列表',
              role: 1,
              parentName: 'BGM'
            }
          },
          {
            path: 'add',
            name: 'BGMAdd',
            component: () => import("@/views/admin/backgroundImage/Add"),
            meta: {
              title: '添加背景图',
              role: 1,
              parentName: 'BGM',
              hide: true,
            }
          },
          {
            path: 'edit',
            name: 'BGMEdit',
            component: () => import("@/views/admin/backgroundImage/Add"),
            meta: {
              title: "编辑背景图",
              role: 1,
              hide: true,
              parentName: 'BGM'
            }
          }
        ],
      },
      {
        path: '/admin/gift',
        name: 'Gift',
        redirect: '/admin/gift/index',
        component: AdminLayout,
        meta: {
          title: '礼品管理'
        },
        children: [
          {
            path: 'index',
            name: 'GiftIndex',
            component: () => import("@/views/admin/gift/Index"),
            meta: {
              title: '礼品列表',
              role: 1,
              parentName: 'Gift'
            }
          },
          {
            path: 'add',
            name: 'GiftAdd',
            component: () => import("@/views/admin/gift/Add"),
            meta: {
                title: '礼品新增/编辑',
                role: 1,
                parentName: 'Gift',
                hide: true
            }
          }
        ]
      },
      {
        path: '/admin/user',
        name: 'User',
        redirect: '/admin/user/sort',
        component: AdminLayout,
        meta: {title: '用户管理'},
        children: [
          {
            path: 'sort',
            name: 'UserSort',
            component: () => import("@/views/admin/user/UserSort"),
            meta: {
              title: '用户排行榜',
              role: 1,
              parentName: 'User'
            }
          },
          {
            path: 'points',
            name: 'UserPoints',
            component: () => import("@/views/admin/user/UserPoints"),
            meta: {
              title: '用户积分变动记录',
              role: 1,
              parentName: 'User'
            }
          },
          {
            path: 'gifts',
            name: 'UserGifts',
            component: () => import("@/views/admin/user/UserGifts"),
            meta: {
              title: '用户兑换记录',
              role: 1,
              parentName: 'User'
            }
          }
        ]
      },{
        path: '/admin/words',
        name: 'adminWords',
        redirect: '/admin/words/index',
        component: AdminLayout,
        meta: {title: '留言管理'},
        children: [
          {
            path: 'index',
            name: 'WordsIndex',
            component: () => import("@/views/admin/word/Index"),
            meta: {
              title: '留言列表',
              role: 1,
              parentName: 'adminWords'
            }
          },
          {
            path: 'ban',
            name: 'banWords',
            component: () => import("@/views/admin/word/BanWords.vue"),
            meta: {
              title: '敏感词列表',
              role: 1,
              parentName: 'adminWords'
            }
          }
        ]
      }
    ]

  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes.concat(adminRoute)
})

export default router
