<template>
  <div class="header-info">
      <div class="site-name">
          <h4>{{title}}</h4>
      </div>
      <div class="user-info">
          <div class="index-icon" style="font-size: 20px; margin-right: 20px;color: #333;cursor:pointer;">
              <el-icon @click="toHome"><home-filled /></el-icon>
          </div>
          <div class="avatar">
            <el-avatar :src="userInfo.avatar"></el-avatar>
          </div>
          <div class="nick-name">
              <span>{{userInfo.nickname}}</span>
          </div>
          <div class="icon" style="font-size: 20px;color: #AAAAAA;cursor:pointer;" @click="logout">
              <el-icon ><promotion /></el-icon>
          </div>
      </div>
  </div>
  <div class="main">
    <router-view></router-view>
  </div>

</template>

<script setup>
import {ref, reactive} from "vue";
import {Promotion, HomeFilled} from "@element-plus/icons-vue"
import {useRouter} from "vue-router";

const userInfo = ref(reactive({
  nickname: localStorage.getItem('nickname'),
  avatar: localStorage.getItem('avatar') ? localStorage.getItem('avatar') : 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'
}))
const router = useRouter();
const title = ref('宝龙年度树向生长后台管理系统')
const logout = () => {
    localStorage.clear()
    router.push('/login')
}
const toHome = () => {
    const routerData = router.resolve({path: '/'})
    window.open(routerData.href, '_blank')
}


</script>

<style scoped lang="scss">
    .header-info {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 2px 5px 0px rgba(15, 26, 54, 0.05);
        &, & * {
            box-sizing: border-box;
        }
        .user-info {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 40px;
            .nick-name {
              font-size: 16px;
              font-weight: bold;
              color: #333;
              margin: 0 10px;
            }
        }
        .site-name {
            padding-left: 40px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
        }
    }
    .main {
        width: 100%;
        height: calc(100vh - 60px);
    }
</style>