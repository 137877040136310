import router from "@/router";
import {adminRoute} from "@/router";
import store from "@/store"


const whiteList = ['AdminLogin', 'Index'];

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    if (whiteList.indexOf(to.name) !== -1) {
        next();
        return;
    }
    if (to.meta.role && localStorage.getItem('token')) {
        store.dispatch('asyncSetMenu', adminRoute)
        next()
    } else {
        next({path: '/login'})
    }

})