import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "./permission"
import zhCn from "element-plus/es/locale/lang/zh-cn"
import "@/assets/style/index.scss"
import { vueBaberrage } from "vue-baberrage"


createApp(App)
    .use(store)
    .use(router)
    .use(vueBaberrage)
    .use(ElementPlus, {locale: zhCn,}).mount('#app')
